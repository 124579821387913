// src/stores/menu.js
import { defineStore } from 'pinia';
import { watch } from 'vue';

export const useMenuStore = defineStore('menu', {
    state: () => ({
        menus: []
    }),
    actions: {
        setMenuItems(items) {
            this.menus = items;
        },
        clearMenu() {
            this.menus = [];
        },
        defaultMenu(){
            this.menus = [
                {
                    label: '',
                    items: [
                        {
                            label: '系統管理',
                            icon: 'pi pi-fw pi-bookmark',
                            id: 'system',
                            items: [
                                { id: 'system-1', label: '用戶管理', icon: 'pi pi-fw pi-bookmark',to: { name: 'User' } },
                            ]
                        },
                        {
                            label: '社團管理',
                            icon: 'pi pi-fw pi-bookmark',
                            id: 'clubs',
                            items: [
                                { id: 'clubs-list', label: '社團列表', icon: 'pi pi-fw pi-bookmark',to: { name: 'clublist' } },
                                { id: 'clubs-category', label: '社團分類管理', icon: 'pi pi-fw pi-bookmark',to: { name: 'clubcategory' } },
                                { id: 'clubs-location', label: '社團據點管理', icon: 'pi pi-fw pi-bookmark',to: { name: 'clublocation' } },
                            ]
                        },
                        {
                            label: '活動管理',
                            icon: 'pi pi-fw pi-bookmark',
                            id: 'events',
                            items: [
                                { id: 'events-lists', label: '活動列表', icon: 'pi pi-fw pi-bookmark',to: { name: 'eventlist' } },
                                { id: 'events-category', label: '活動分類管理', icon: 'pi pi-fw pi-bookmark',to: { name: 'eventcategory' } },
                            ]
                        },
                        {
                            label: '最新消息管理',
                            icon: 'pi pi-fw pi-bookmark',
                            id: 'news',
                            items: [
                                { id: 'news-list', label: '最新消息列表', icon: 'pi pi-fw pi-bookmark',to: { name: 'newslist' } },

                            ]
                        },

                    ]
                },

            ]
        },
        guestMenu(){
            this.menus = [
                {
                    label: '',
                    items: [
                        {
                            label: '商家管理',
                            icon: 'pi pi-fw pi-bookmark',
                            id: 'transaction',
                            items: [
                                { id: 'store-1', label: '交易管理', icon: 'pi pi-fw pi-bookmark',to: { name: 'Storetransaction' } },
                            ]
                        },
                    ]
                },

            ]
        },

    }
});


