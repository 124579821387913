import { createRouter, createWebHistory } from 'vue-router';
import AppLayout from '@/layout/AppLayout.vue';
import { useAuthStore } from '@/store/auth';

//公共路由
const constantRoutes = [
    {
        path: '/',
        name: 'Home',
        redirect: { name: 'clublist' }// 或者重定向到任何您想要作為首頁的路由
    },
	{
		path: '/auth/login',
        name: 'Login',
        component: () => import('@/views/pages/auth/Login.vue'),
        hidden: true,
        meta:{
            requiresAuth: false
        }
	},
    {
        path: '/auth/access',
        name: 'noAccess',
        component: () => import('@/views/pages/auth/Access.vue'),
        hidden: true,
        meta:{
            requiresAuth: false
        }
    },
    {
        path: '/auth/error',
        name: 'Error',
        component: () => import('@/views/pages/auth/Error.vue'),
        hidden: true,
        meta:{
            requiresAuth: false
        }
    },
    {
		path: '/notfound',
        name: 'NotFound',
        component: () => import('@/views/pages/NotFound.vue'),
		hidden: true,
        meta:{
            requiresAuth: false
        }
	},
];

const dynamicRoutes = [
    {
        path: '/admin',
        name: 'Layout',
        component: () => import('@/layout/AppLayout.vue'),
        children: [
            {
                path: '',
                name: 'Dashboard',
                component: () => import('@/views/Dashboard.vue'),
                hidden: false,
                meta:{
                    requiresAuth: true
                }
            },
            {
                path: 'user',
                name: 'User',
                component: () => import('@/views/pages/users/Index.vue'),
                hidden: false,
                meta:{
                    requiresAuth: true
                }
            },
            {
                path: 'clublist',
                name: 'clublist',
                component: () => import('@/views/pages/club/clublist.vue'),
                hidden: false,
                meta:{
                    requiresAuth: true
                }
            },
            {
                path: 'clubcategory',
                name: 'clubcategory',
                component: () => import('@/views/pages/club/clubcategory.vue'),
                hidden: false,
                meta:{
                    requiresAuth: true
                }
            },
            {
                path: 'clublocation',
                name: 'clublocation',
                component: () => import('@/views/pages/club/clublocation.vue'),
                hidden: false,
                meta:{
                    requiresAuth: true
                }
            },
            {
                path: 'eventcategory',
                name: 'eventcategory',
                component: () => import('@/views/pages/event/eventcategory.vue'),
                hidden: false,
                meta:{
                    requiresAuth: true
                }
            },
            {
                path: 'newslist',
                name: 'newslist',
                component: () => import('@/views/pages/news/newslist.vue'),
                hidden: false,
                meta:{
                    requiresAuth: true
                }
            },
            {
                path: 'eventlist',
                name: 'eventlist',
                component: () => import('@/views/pages/event/eventlist.vue'),
                hidden: false,
                meta:{
                    requiresAuth: true
                }
            },
            {
                path: 'special',
                name: 'special_index',
                component: () => import('@/views/pages/special/index.vue'),
                hidden: true,
                meta:{
                    requiresAuth: true
                },

                children: [
                    {
                        path: 'info',
                        name: 'special_info',
                        component: () => import('@/views/pages/special/info.vue'),
                        hidden: true,
                        meta:{
                            requiresAuth: true
                        }
                    },
                    {
                        path: 'article',
                        name: 'special_article',
                        component: () => import('@/views/pages/special/articleslist.vue'),
                        hidden: true,
                        meta:{
                            requiresAuth: true
                        }
                    },
                    {
                        path: 'points',
                        name: 'special_points',
                        component: () => import('@/views/pages/special/points.vue'),
                        hidden: true,
                        meta:{
                            requiresAuth: true
                        }
                    },
                    {
                        path: 'stores',
                        name: 'special_stores',
                        component: () => import('@/views/pages/special/stores.vue'),
                        hidden: true,
                        meta:{
                            requiresAuth: true
                        }
                    },
                    {
                        path: 'games',
                        name: 'special_games'  ,
                        component: () => import('@/views/pages/special/games.vue'),
                        hidden: true,
                        meta:{
                            requiresAuth: true
                        }
                    },
                    {
                        path: 'manage/points',
                        name: 'special_mange_coins',
                        component: () => import('@/views/pages/special/transactionlist.vue'),
                        hidden: true,
                        meta:{
                            requiresAuth: true
                        }
                    },
                    {
                        path: 'manage/gamelevels',
                        name: 'special_mange_gamelevels',
                        component: () => import('@/views/pages/special/Crud.vue'),
                        hidden: true,
                        meta:{
                            requiresAuth: true
                        }
                    },
                    {
                        path: 'manage/exchanges',
                        name: 'special_mange_exchanges',
                        component: () => import('@/views/pages/special/transactionlist.vue'),
                        hidden: true,
                        meta:{
                            requiresAuth: true
                        }
                    }
                ]
            },
        ]
    },

];

const routes = [...constantRoutes, ...dynamicRoutes];

const router = createRouter({
    history: createWebHistory(),
    routes
});

// 路由守衛，在每次路由跳轉前執行
router.beforeEach(async (to, from) => {

    const authStore = useAuthStore();
    if (to.meta.requiresAuth && !authStore.isAuthenticated){
        await authStore.checkAuth(); // 檢查用戶是否已經登錄
        if (!authStore.isAuthenticated)
        {
            return { name: 'Login',  replace: true };
        }
    }
    const currentTime = new Date();
    const timeDifference = (currentTime - authStore.lastupdate) / 1000 / 60; // 轉換為分鐘
    if (timeDifference >= 3) {
        await authStore.updatecsrftoken();// 每1分鐘更新CSRF token
    }

    if (authStore.isAuthenticated && to.name === 'Login'){
        return { name: 'clublist', replace: true }
    }
    return true;

});


export default router;
